.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 20px;
}

.navbar-logo {
  cursor: pointer;
}

@keyframes loadingAnimation {
  0% {
    background-color: #ffffff; /* Starting color */
  }
  50% {
    background-color: #a3a3a3; /* Midway color */
  }
  100% {
    background-color: #ffffff; /* Ending color */
  }
}

.navbar-load {
  position: absolute;
  left: -7px;
  border-radius: 15px;
  z-index: -1;
  width: 101%;
  height: 100%; /* Height of the loading bar */
  background-color: #3498db; /* Initial color */
  animation: loadingAnimation 3s linear infinite; /* Use the keyframes animation */
}

.navbar-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: rgba(255, 255, 255, 0.622);
}

.navbar-menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10%;
  background: var(--primary-color);
  border-radius: 10px;
  width: 40%;
  height: 40%;
}

.navbar-menu ul {
  list-style-type: none;
}

.navbar-menu li {
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
}

.navbar-menu-close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
}

.navbar-hamburger {
  cursor: pointer;
}

.navbar p {
  padding: 0;
}

.navbar-right-items {
  display: flex;
  align-items: center;
}

.navbar-right-items button {
  margin: 0 5px;
  height: 75%;
  padding: 11px 15px;
}

.navbar-right-items p {
  margin-right: 40px;
  align-self: center;
}

.navbar-home-icon {
  margin-right: 10px;
  transform: translateY(1.5px);
  cursor: pointer;
  transition: opacity 0.7s ease;
}

.navbar-home-icon:hover {
  opacity: 0.5;
}

.navbar-avatar {
  margin-right: 15px;
  cursor: pointer;
  transition: opacity 0.7s ease;
  border: 2px solid var(--dark-gray-color);
}

.navbar-avatar:hover {
  opacity: 0.75;
}

.navbar-notification {
  position: absolute;
  width: 15px; 
  height: 15px;
  background: red;
  right: 50px; 
  top: 5px;   
  z-index: 900;
  border-radius: 50%; 
}

.navbar-notifications {
  position: absolute;
  width: 30%;
  height: auto;
  right: 70px;
  top: 70px;
  border-radius: 15px;
  padding: 10px;
  background: white;
  z-index: 3;
}

@media screen and (max-width: 700px) {
  .navbar-load {
    width: 104%;
  }

  .navbar-menu {
    width: 75%;
    height: 75%;
    bottom: 0;
  }
}
