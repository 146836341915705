.bet-has-voted-outside-wrapper {
  padding: 0 20px;
}

.bet-has-voted-wrapper {
  background: white;
  border: 3px solid var(--secondary-color);
  padding: 7px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
}