.dash-invite-wrapper {
  display: inline-block;
  background: var(--light-gray);
  border: 2px solid black;
  padding: 10px;
  margin-left: 5px;
  border-radius: 10px;
}

.dash-invite-button {
  margin-right: 5px;
}

.dash-invite-participants {
  display: inline;
}