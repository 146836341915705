.card-list-wrap {
  display: flex;
  justify-content: center;
  margin: 0 5px;
}

.card-list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

/* Tablet */
@media (max-width: 1200px) {
  .card-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile */
@media (max-width: 700px) {
  .card-list-grid {
    grid-template-columns: 1fr;
  }
}