.avatar-wrapper {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}


.avatar-img {
  object-fit: cover;
  object-position: center;
}

.avatar-overlay {
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar-text {
  color: white;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
