.bet-page-home-button {
  margin: 10px 0;
}

.bet-page-winner-text {
  margin: 0;
  padding: 0;
}

.bet-page-winner {
  background: var(--secondary-color);
  display: inline-block;
  padding: 10px;
  border: solid black 2px;
  border-radius: 50px;
}

.bet-page-winner p {
  padding-bottom: 0;
}

.bet-page-avatar {
  margin-top: 30px;
}
