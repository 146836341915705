.bet-invite-wrapper {
  background: white;
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
}

.bet-invite-wrapper input {
  margin-right: 10px;
  margin-bottom: 10px;
}