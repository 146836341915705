.vote-on-vote-outside-wrapper {
  padding: 0 20px;
}

.vote-on-vote-wrapper {
  background: white;
  border: 2px solid var(--secondary-color);
  padding: 7px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
  word-wrap: break-word;
}

.vote-on-vote-label {
  margin-right: 5px;
  font-size: 1.2em;
}

.vote-on-vote-form-item {
  margin-bottom: 10px;
}

.vote-on-vote-button {
  margin-top: 10px;

}