:root {
  /* color */
  --primary-color: #FC4445;
  --secondary-color: #3feee6;
  --bluegray-color: #97caef;
  --bluegray-color-darker: #427193;
  --dark-color: #55bcc9;
  --light-color: #cafafe;
  --light-gray: #DADADA;
  --dark-gray-color: #333333;
  --off-white: #f0eadc;
  --success-color: #81c784;
  --golden-color: #FFD700;
  /* font */
  --avatar-size: 100px;
}

.form p {
  padding: 0 0 2px 0;
}

.form input {
  margin-bottom: 1.2rem;
}


.form button {
  display: block;
  margin-bottom: 1.2rem;
}

.form select {
  margin-bottom: 1.2rem;
}

.form textarea{
  margin-bottom: 1.2rem;
}

.info-title-light {
  color: white;
}
.info-title-dark {
  color: var(--primary-color)
}
.info-title-blue {
  color: var(--bluegray-color-darker)
}