.bet-lost-outside-wrapper {
  padding: 0 20px;
}

.bet-lost-wrapper {
  background: var(--primary-color);
  color: white;
  border: 2px solid black;
  padding: 7px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
}

.bet-lost-image {
  height: auto;
  width: auto;
  border: white 5px solid;
  border-radius: 20px;
}

@media screen and (max-width: 1024px) {
  .bet-lost-image {
    height: 205px;
    width: 100%;
    border: white 5px solid;
    border-radius: 20px;

    
  }

  .bet-lost-outside-wrapper {
    padding: 0;
  }
}