.card-h1 {
  color: red;
}
.card {
  color: var(--dark-gray-color);
  padding: 1rem;
  margin-top: 0.7rem;
  width: auto;
  height: auto;
  border-radius: 10px;
  /* border: 2.5px solid var(--dark-gray-color); */
  border: 2.5px solid black;
  transition: transform 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.card-info {
  background: var(--off-white);
  border: 2px solid black;
  padding: 2px 2px 2px 7px;
  border-radius: 10px;
  word-wrap: break-word;
}

.card-info:not(:first-child) {
  margin-top: 5px;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.card-your-bet-bkg {
  background: var(--primary-color)
}
.card-their-bet-bkg {
  background: var(--secondary-color)
}

.card-winner-bkg {
  background: #9879bf
}

@media only screen and (max-width: 750px) {
  .card {
    width: 88vw;
  }
}




