.child-bets-wrapper {
  background: white;
  border: 2px solid black;
  padding: 7px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
  background: var(--primary-color);
}

.child-bets-wrapper input {
  margin-right: 10px;
  margin-bottom: 10px;
}

.child-bets-bet-text {
  font-weight:900;
}