h3 {
  margin: 10px 0;
}

.vote-init-button {
}

.vote-init-wrapper {
  padding: 20px;
}

.vote-init-form-wrapper {
  background: white;
  border: 2px solid var(--secondary-color);
  padding: 7px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
}

.vote-init-item-wrapper {
  margin: 0 0 20px;
}

.vote-init-label {
  font-size: 1.2em;
  margin-left: 2px;
}

.vote-init-checkbox {
  margin-left: 5px;
}

.vote-init-reason-input {
  margin-left: 5px;
  width: 70%;
}

.vote-init-submit-button {
  margin: 20px 0 2px 0;
  display: block;
}


