.manual-paid-confirm-outside-wrapper {
  padding: 0 20px;
}

.manual-paid-confirm-wrapper {
  background: white;
  border: 2px solid var(--secondary-color);
  padding: 7px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
}