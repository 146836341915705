@import url("https://fonts.googleapis.com/css2?family=Jura:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Gilroy");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Gilroy", sans-serif;
  background: var(--off-white);
}

p, li, ul {
  font-family: "Jura", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0 0 1rem 0;
}

input, textarea, select {
  font-size: 16px;
}

input {
  padding: 2px;
  border-radius: 5px;
  border: var(--dark-gray-color) 2px solid;
}

input:focus {
  border: var(--dark-color) 2px solid;
  outline: none;
}

select {
  padding: 2px;
  border-radius: 5px;
  border: var(--dark-gray-color) 2px solid;
}

select:focus {
  border: var(--dark-color) 2px solid;
  outline: none;
}

textarea {
  padding: 2px;
  border-radius: 5px;
  border: var(--dark-gray-color) 2px solid;
}

textarea:focus {
  border: var(--dark-color) 2px solid;
  outline: none;
}

h1 {
  font-size: 2.5rem;
  line-height: 1.2;
  margin: 2rem 0 1rem 0;
  padding: 0;
}

h2 {
  font-size: 2rem;
  line-height: 1.2;
  margin: 1.5rem 0 1rem 0;
  padding: 0;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 1rem 0 0.5rem 0;
  padding: 0;
}

h4 {
  font-size: 1.2rem;
  line-height: 1.2;
  margin: 1rem 0 0.5rem 0;
  padding: 0;
}

h5 {
  font-size: 1rem;
  line-height: 1.2;
  margin: 1rem 0 0.5rem 0;
  padding: 0;
}

h6 {
  font-size: 0.8rem;
  line-height: 1.2;
  margin: 1rem 0 0.5rem 0;
  padding: 0;
}

a {
  color: var(--dark-color);
  text-decoration: none;
  transition: opacity .5s ease;
}

a:hover {
  opacity: .75;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(255, 255, 255, 0.622);
}

.loader img {
  width: 20%;
  border-radius: 50px;
}

.loader p {
  position: absolute;
  top: 55%;
  color:white;
  font-size: 1.5em;
}

@keyframes loadingAnimation {
  0% {
    background-color: #ffffff; /* Starting color */
  }
  50% {
    background-color: var(--off-white); /* Midway color */
  }
  100% {
    background-color: #ffffff; /* Ending color */
  }
}

.body-load {
  animation: loadingAnimation 3s linear infinite; /* Use the keyframes animation */
}

@media only screen and (max-width: 900px) {
  .loader img {
    width: 70%;
  }
}