.bet-info-wrapper {
  background: white;
  border: 2px solid black;
  padding: 7px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
}

.bet-info-wrapper p:last-child {
  padding-bottom: 0;
}