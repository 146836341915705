.bet-comments-wrapper {
  background: white;
  border: 2px solid black;
  padding: 7px;
  border-radius: 10px;
  width: auto;
  margin: 10px 0;
  word-wrap: break-word;
}

.bet-comments-wrapper input {
  margin-right: 10px;
  margin-bottom: 10px;
}
