.notifications-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notifications-notification {
  cursor: pointer;
  background: var(--secondary-color);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 7px;
}