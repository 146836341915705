/* animation */
@keyframes slideDown {
  0% {
    top: -75px;
  }
  100% {
    top: 7px;
  }
}

@keyframes vanish {
  0% {
    /* top: 7px; */
    opacity: 1;
  }
  100% {
    /* top: -75px; */
    opacity: 0;
  }
}

.alert {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 75px;
  border-radius: 30px;
  z-index: 100;
  padding: 0 15px;
}

.alert-success {
  background: var(--success-color);
}

.alert-fail {
  background: pink;
}

.alert.vanish {
  animation: vanish 1s ease;
}

.alert:not(.vanish) {
  animation: slideDown 1s ease;
}

.alert-text {
  color: var(--dark-gray-color);
  padding: 0;
}
