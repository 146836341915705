.button-prop-default {
  padding: 10px 30px;
  color: var(--dark-gray-color);
  border: var(--dark-gray-color) 1px solid;
  border-radius: 5px;
  background: var(--bluegray-color);
  cursor: pointer;
  transition: opacity ease 0.5s;
}

.button-prop-default:hover {
  opacity: 75%;
}
